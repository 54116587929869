<template>
<div v-if="ready" class="bg-kpmg-purple">
  <b-container style="min-height: 90vh">
    <b-row class="py-4 text-white">
      <b-col>
        <h1>{{control.name}}</h1>
        <p>{{control.description}}</p>
        <div><strong>Attributes</strong></div>
        <div>
          <ol>
            <li v-for="attribute in control.attributes" :key="'control-'+ control.id + '-attr-' + attribute.id">
              <strong>{{attribute.name}}</strong> - {{attribute.description}}
            </li>
          </ol>
        </div>
        <div>Functions: <strong v-for="fun in control.functionsFull" :key="'full-function-' + fun.id">{{fun.name}} |</strong></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
export default {
  components: {
  },
  computed: {
    ad: function () {
      return this.$store.state.appData
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'UT app control', action: 'open UT control' })
    this.load()
  },
  data () {
    return {
      control: false,
      ready: false
    }
  },
  methods: {
    load: function () {
      const control = this.ad.controls.find(element => element.id === this.$route.params.id)
      document.title = control.name
      control.functionsFull = []
      _.each(this.ad.functions, x => {
        if (control.functions.find(element => element === x.id)) {
          control.functionsFull.push(x)
        }
      })
      this.control = control
      this.ready = true
    }
  }
}
</script>

<style>
</style>
